.pm-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & > nav {
    flex-grow: 0;
    z-index: 10;
  }

  & > main {
    position: relative;
    flex-grow: 1;
    min-height: 0;
    height: fit-content;
    display: flex;
    align-items: stretch;
  }

  & > footer {
    flex-grow: 0;
  }

  // Map Maker pages have no footers, and they manage their own scrolling.
  &.mapmaker-layout {
    height: 100%;

    & > footer {
      display: none;
    }
  }
}
