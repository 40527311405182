:root {
  /* Situational colors */
  --primary-color: #ffffff;
  --accent-color: #00baf2;
  --accent-color-light: #f2fafd;
  --accent-text-color: #ffffff;
  --text-color: #181818;
  --link-color: var(--accent-color);
  --info-color: #00baf2;
  --info-color-light: #e7f9ff;
  --info-text-color: var(--white);
  --warning-color: #cd8721;
  --warning-text-color: var(--light-black);
  --error-color: #cd2121;
  --error-text-color: var(--white);
  --success-color: #086d16;
  --success-text-color: var(--white);
  --discount-color: #086d16;
  --discount-text-color: var(--white);

  /* Specific colors */
  --black: #333333;
  --light-black: #555555;

  --light-grey: #dcddde;
  --grey: #999999;
  --dark-grey: #6e6e6e;

  --white: #ffffff;
  --dark-white: #eeeeee;

  /* Layout Values */
  --column-width: 1320px;
}

html {
  height: 100%;
}

body {
  color: var(--text-color);
  margin: 0;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
span.link {
  color: var(--link-color);
  cursor: pointer;
  font-weight: normal;
}

/* Remove annoying 'hover' effect on mobile. */
@media (hover: none) {
  a:hover,
  span.link:hover {
    color: inherit !important;
  }
}

a.subtle,
span.link.subtle {
  color: var(--text-color);
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-family: Montserrat, Lato, "Brandon Grotesk", Roboto;
}

/* 
 * IOS automatically zooms input fields when the font size is less than 16px. This should help
 * minimize those scenarios. for more information see:
 * https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
 */
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}
