.pm-layout {
  /* Mapmaker layout "Theme" */
  --mm-link-color: var(--accent-color);

  --mm-accent-color: var(--accent-color);
  --mm-accent-color-light: var(--accent-color-light);
  --mm-accent-text-color: var(--accent-text-color);

  --mm-info-color: var(--link-color);
  --mm-info-text-color: var(--white);
  --mm-warning-color: var(--warning-color);
  --mm-warning-text-color: var(--white);
  --mm-error-color: var(--error-color);
  --mm-error-text-color: var(--white);

  --mm-discount-color: var(--discount-color);

  --auth-box-primary-color: var(--primary-color);
  --auth-box-primary-text-color: var(--text-color);
  --auth-box-secondary-color: var(--accent-color);
  --auth-box-secondary-text-color: #ffffff;
  --auth-box-error-color: #b83a3a;
  --auth-box-action-color: var(--auth-box-secondary-color);
  --auth-box-action-text-color: var(--auth-box-secondary-text-color);
  --auth-box-link-color: var(--auth-box-action-color);
}
