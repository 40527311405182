.pm-page {
  &.area-standard {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
    padding-bottom: 4em;
    // We still want some padding just above 1024
    @media (max-width: 1100px) {
      padding: 2em 2em 4em 2em;
    }
  }
  &.area-full {
    width: 100%;
  }
}
