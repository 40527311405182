#slideout-menu {
  position: absolute;
  z-index: 20000;
  top: 0;
  left: 0;

  .backdrop {
    position: fixed;
    top: 0;
    bottom: -100px;
    left: 0;
    width: 100vw;
    background: #000;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s ease-out;
    pointer-events: visible;
  }
  .close-button {
    position: fixed;
    z-index: 1;
    top: 30px;
    right: 0;
    width: 90px;
    font-size: 45px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s ease-out;
    pointer-events: none;
  }

  .menu-content {
    position: fixed;
    z-index: 100;
    left: calc(-100vw + 90px);
    width: calc(100vw - 90px);
    height: 100vh;
    overflow-x: hidden;
    margin-top: 0;
    padding: 0em;
    padding-bottom: 2em;
    background: #fff;
    transition: left 0.1s ease-out;
  }

  &.open {
    .backdrop {
      visibility: visible;
      opacity: 0.7;
    }
    .close-button {
      visibility: visible;
      opacity: 1;
    }
    .menu-content {
      left: 0;
    }
  }
}
