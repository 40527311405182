.sideNavContent {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    h3 {
      font-weight: bold;
      margin: 0;
      padding: 0.5em 1em 0em 1em;
    }

    a {
      color: var(--text-color);
    }
    a.active {
      li {
        background: var(--accent-color);
        color: var(--dark-white);
        font-weight: bold;
      }
    }

    li {
      position: relative;
      padding: 0.5em 1em;
      font-size: 1.2em;
      margin-top: 4px;
    }
  }
}

.logo {
  display: block;
  margin: 1em auto;
}

.group {
  border-top: 1px solid var(--grey);
}

.account {
  .email {
    padding: 0.5em;
    text-align: center;
    font-weight: bold;
  }

  .buttons {
    display: flex;
    > a,
    .link,
    > a {
      flex-basis: 50%;
      padding: 1em;
      cursor: pointer;
      color: var(--text-color);
      text-align: center;
      border-right: 1px solid var(--grey);

      &:hover {
        color: var(--accent-color);
      }

      &:last-child {
        border: none;
      }
    }
  }
}

.badge {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1rem;
  padding: 4px 8px;
  background-color: var(--accent-color);
  border-radius: 4px;
  color: var(--white);
}
