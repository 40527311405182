.topNav {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em 2em;

  &.inverted a,
  &.inverted ul.menu a,
  &.inverted {
    color: #fff;
  }

  --menu-highlight-color: var(--light-black);
  --menu-active-color: var(--accent-color);
  &.inverted {
    --menu-highlight-color: var(--dark-white);
    --menu-active-color: var(--white);
  }

  > * {
    text-align: center;
  }

  > *:first-child,
  > *:last-child {
    flex-basis: 200px;
    flex-grow: 1;
    flex-shrink: 1;
    // More room for mid-sized screens.
    @media screen and (max-width: 1024px) {
      flex-basis: 120px;
    }
  }
}

.menu {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;

  li {
    display: inline;
    margin: 0 1em;
    font-size: 1.2em;

    a {
      color: black;
      cursor: pointer;
      &:hover {
        padding-bottom: 2px;
        border-bottom: 2px solid var(--menu-highlight-color);
      }
      // Funny selector here is because we're using the react-router nav link so we don't want it to
      // be compiled to another name.
      &[class="active"] {
        padding-bottom: 2px;
        border-bottom: 2px solid var(--menu-active-color);
      }
    }
  }
}

.logo {
  text-align: left;
  a img {
    margin-bottom: -4px;
    max-width: 100%;
  }
}

.secondary {
  text-align: right;
  a {
    margin-left: 1.5em;
  }
}

.projectCountLabel {
  display: inline-block;
  background: var(--dark-grey);
  padding: 3px 7px 3px 6px;
  border-radius: 5px;
  text-align: center;
  color: var(--white);
  margin-left: 4px;
  font-size: 0.9rem;
  vertical-align: top;
  font-weight: bold;
  line-height: 1rem;
}

[class="active"] .projectCountLabel {
  background: var(--accent-color);
}

.inverted .projectCountLabel {
  background: var(--white);
  color: var(--dark-grey);
  mix-blend-mode: screen;
}
