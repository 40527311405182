#pm-footer {
  padding: 2em;
  background-color: var(--dark-white);

  .content {
    display: grid;
    max-width: 1024px;
    margin: 0 auto;

    grid-template-columns: 1fr 1fr 2fr;
    grid-template-areas:
      "links1    links2    stamp"
      "copyright copyright copyright";
    gap: 1em;

    .links {
      ul,
      li {
        margin: 0;
        padding: 0;
        list-style: none;
      }
    }
    .stamp {
      grid-area: stamp;
      text-align: center;
      font-family: cursive;
      font-size: 1.2em;
    }

    .copyright {
      grid-area: copyright;
      margin-top: 1em;
      padding-top: 1em;
      text-align: center;
      color: var(--light-black);
    }

    /* Small screens */
    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "links    links"
        "stamp     stamp"
        "copyright copyright";
      .links:first-of-type {
        border-right: 1px solid var(--grey);
      }
      .stamp {
        column-span: 2;
        padding-top: 2em;
        border-top: 1px solid var(--grey);
      }
    }

    /* Large screens */
    @media (min-width: 769px) {
      .links {
        border-right: 1px solid var(--grey);
      }
      .copyright {
        border-top: 1px solid var(--grey);
        padding-top: 3em;
        padding-bottom: 1em;
      }
    }
  }
}
