#side-nav {
  padding: 1em;
  padding-bottom: 0.5em;
  display: flex;

  &.inverted,
  &.inverted a {
    color: #fff;
  }

  div {
    flex-grow: 1;
    text-align: center;
  }

  div:first-child,
  div:last-child {
    flex-basis: 2em;
    flex-grow: 0;
  }

  .logo {
    margin-top: -4px;
  }
}
