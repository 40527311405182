#cart-icon {
  position: relative;
  .qty {
    &:empty {
      display: none;
    }
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    background: var(--accent-color);
    color: var(--accent-text-color);
    min-width: 22px;
    height: 22px;
    text-align: center;
    line-height: 10px;
    padding: 5px;
    border-radius: 1em;
    border: 1px solid;
  }
}
