.centered {
  /* Make the container take up the whole width and height of the element. */
  &.stretchHeight {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  /* Make the content centered. */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
